import React, {useContext} from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import LayoutMaster from "../components/layout"
import '../styles/global.css'
import section from '../styles/sections.module.css'
import PageBanner from '../components/pageBanner'
import {GlobalStateContext} from '../context/GlobalContextProvider'
import flex from '../styles/flexbox.module.css';

export default function Managers({data, location}){
    const state = useContext(GlobalStateContext)
    const {managers: {_private, _public, contact, corp, back}, home: {managers}, menu} = state.language
    const siteTitle = data.site.siteMetadata.title
    
    return(
        <LayoutMaster location={location} title={siteTitle}>
            <SEO title={menu[3].text} />
            <PageBanner>
                {location.hash === '#private' ?
                    <>
                        <h2>{_private.title}</h2>
                        {_private.text.map((item, index)=><p key={index}>{item}</p>)}
                    </> :
                    location.hash === '#public' ?
                    <>
                        <h2>{_public.title}</h2>
                        {_public.text.map((item, index)=><p key={index}>{item}</p>)}
                    </> :
                    <>
                        <h2 className={flex.selfCenter}>{menu.managers}</h2>
                        <section className={`${flex.container} ${flex.justifyCenter} ${flex.spacing} ${flex.alignCenter}`} style={{marginTop: 30}}>
                            <Link to="/managers#private" className={`${section.imageCards} ${flex.container} ${flex.alignEnd}`} style={{backgroundImage: "url('https://storage.googleapis.com/assets.qzela.com.br/images/landing/managers/services.png')"}}>
                                <h5>{managers.private} +</h5>
                            </Link>
                            <Link to="/managers#public" className={`${section.imageCards} ${flex.container} ${flex.alignEnd}`} style={{backgroundImage: "url('https://storage.googleapis.com/assets.qzela.com.br/images/landing/managers/publico.png')"}}>
                                <h5>{managers.public} +</h5>
                            </Link>        
                        </section>
                    </>
                }{location.hash!=="" ?             
                    <section className={`${flex.container} ${flex.justifyEnd} ${flex.spacing} ${flex.alignCenter}`}>
                        <Link className={`button`} to="/contact">{contact.btn}</Link>
                        <a href="https://corp.qzela.com.br/" className={`button`} target="_blank" rel="noopener noreferrer">{corp.btn}</a>
                        <Link className={`button`} to="/managers">{back}</Link>
                    </section>
                    : ''
                }
            </PageBanner> 
        </LayoutMaster>
    )
}
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`